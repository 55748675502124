/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* If the user prefers dark mode, 
we of course apply color-scheme: dark, as usual */
@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

/* If the user prefers light mode, 
we still enforce color-scheme: dark, despite the user preference */
@media (prefers-color-scheme: light) {
  html {
    color-scheme: dark;
  }
}

@layer base {
  html,
  body {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    font-display: optional;
    @apply min-h-[600px];
    @apply min-w-80;
    @apply bg-gray-100 dark:bg-darkmode-900 font-inter;
    scroll-padding-top: 80px;
  }
  a {
    @apply text-yellow-500 !important;
  }
  .whiteanchor {
    a {
      @apply text-white !important;
    }
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-VariableFont_wght.ttf");
    font-weight: 100;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-ExtraLight.ttf");
    font-weight: 200;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Light.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Medium.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Regular.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "jetbrains";
    src: url("/public/fonts/JetBrainsMono/JetBrainsMono-Bold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-ExtraLight.ttf");
    font-weight: 100;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-Light.ttf");
    font-weight: 200;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-Medium.ttf");
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-Regular.ttf");
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-SemiBold.ttf");
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-Bold.ttf");
    font-weight: 600;
    font-style: normal;
    font-display: block;
  }
  @font-face {
    font-family: "inter";
    src: url("/public/fonts/Inter/Inter-ExtraBold.ttf");
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 13px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #fafafa;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 100vh;
    border: 2.5px solid #fafafa;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #b0b0b0;
  }
}

@layer utilities {
  .darkscrollbar::-webkit-scrollbar {
    width: 10px;
  }

  .darkscrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #101010;
  }

  .darkscrollbar::-webkit-scrollbar-thumb {
    background: #1c1c1c;
    border-radius: 100vh;
    border: 2.5px solid #1c1c1c;
  }

  .darkscrollbar::-webkit-scrollbar-thumb:hover {
    background: #282828;
  }
}

@layer utilities {
  .bg-darkmode-900 {
    @apply bg-gradient-to-r from-newdarkmode-800 to-newdarkmode-700;
  }
  .bg-darkmode-900 {
    @apply bg-gradient-to-r from-newdarkmode-900 to-newdarkmode-800;
  }
  .bg-darkmode-900down {
    @apply bg-gradient-to-b from-newdarkmode-800 to-newdarkmode-700;
  }
  .lightbg-darkmode-900 {
    @apply bg-gradient-to-r from-gray-300 to-gray-200;
  }
}

.without-ring {
  @apply focus:ring-0 focus:ring-offset-0;
}

datalist#markers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  writing-mode: horizontal-tb;
  height: 10px;
}

input[type="range"] {
  --thumbSize: 1em;
  --trackSize: 0.25em;
  --thumbBg: #ffcb12;
  --trackBg: #262626;
  --progressBg: #ffcb12;

  /* webkit progress workaround */
  --webkitProgressPercent: 0%;
}

input[type="range"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: var(--thumbSize);
  width: 100%;
  margin: 0;
  padding: 0;
}
input[type="range"]:focus {
  outline: none;
}

/* Thumb */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}
input[type="range"]::-ms-thumb {
  -ms-appearance: none;
  appearance: none;
  width: var(--thumbSize);
  height: var(--thumbSize);
  background-color: var(--thumbBg);
  border-radius: calc(var(--thumbSize) / 2);
  border: none;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  margin-top: calc(((var(--thumbSize) - var(--trackSize)) / 2) * -1);
  cursor: pointer;
}

/* Track */
input[type="range"]::-webkit-slider-runnable-track {
  height: var(--trackSize);
  background-image: linear-gradient(
    90deg,
    var(--progressBg) var(--webkitProgressPercent),
    var(--trackBg) var(--webkitProgressPercent)
  );
}
input[type="range"]::-moz-range-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
}
input[type="range"]::-ms-track {
  height: var(--trackSize);
  background-color: var(--trackBg);
}

/* Progress */
input[type="range"]::-moz-range-progress {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}
input[type="range"]::-ms-fill-lower {
  height: var(--trackSize);
  background-color: var(--progressBg);
  border-radius: calc(var(--trackSize) / 2) 0 0 calc(var(--trackSize) / 2);
}

input[type="checkbox"] {
  color: #ffcb12;
  vertical-align: middle;
  -webkit-appearance: none;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 0;
  border-radius: 15%;
  background-color: #ffffff;
  transition: background 300ms;
  cursor: pointer;
}

/* Pseudo element for check styling */

input[type="checkbox"]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

/* Checked */

input[type="checkbox"]:checked {
  background-color: currentcolor;
}

input[type="checkbox"]:checked::before {
  box-shadow: none;
}

/* Disabled */

input[type="checkbox"]:disabled {
  background-color: #ccd3d8;
  opacity: 0.84;
  cursor: not-allowed;
}

/* IE */

input[type="checkbox"]::-ms-check {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
  box-shadow: inset 0 0 0 1px #ccd3d8;
}

.swiper-pagination-bullet {
  background-color: white !important;
  display: inline-block;
  margin-top: 2em;
  color: gray !important;
}
.swiper-pagination-bullet-active-next,
.swiper-pagination-bullet-active-next-next {
  background-color: white !important;
}
.swiper-pagination-bullet-active-prev {
  background-color: gray !important;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
